import React from 'react'
import ReactPlayer from 'react-player/youtube'

function Courses() {
    // COURSES
    const courses = [
        {
            id: 1,
            title: "Maths Course",
            description: "This is a maths course",
            link: "https://youtu.be/xCgggTSN1rU?si=O4SjrQp70WTkO6vC",
            date: "2022-01-01",
            duration: "1 hour",
        },
        {
            id: 2,
            title: "Science Course",
            description: "This is a maths course",
            link: "https://youtu.be/hI9HQfCAw64?si=PEmMgDxT1BvkLCfJ",
            date: "2022-01-01",
            duration: "1 hour",
        }
    ];

    return (
        <div className="h-full flex items-start justify-center gap-x-2 relative">
            <div className="h-full p-3 w-800 overflow-y-auto text-center">
                <div className="px-4 py-3 mb-4 rounded-md bg-white flex items-center justify-between shadow">
                    <h3>All meetings</h3>
                    <h3 className="rounded-lg px-1 bg-gray-200">{courses.length}</h3>
                </div>
                <div>
                    {courses.map((course) => {
                        return <div key={course.id} className='h-100 w-100 p-5 bg-white flex flex-col gap-x-5 mt-2 rounded-lg'>
                            <ReactPlayer url={course.link} controls={true} height={300} width={700} />
                            <div className='flex flex-col items-start w-full'>
                                <h3 className='text-2xl'>{course.title}</h3>
                                <p>{course.description}</p>
                                <div className='flex justify-between w-full mt-5'>
                                    <span className='px-2 py-1 rounded-md bg-gray-200'>{course.date}</span>
                                    <span className='px-2 py-1 rounded-md bg-green-200'>{course.duration}</span>
                                </div>
                            </div>
                        </div>;
                        })
                    }
                </div>
            </div>
        </div>);
}

export default Courses